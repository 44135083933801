import { Box, Button, Card, Container, Flex } from '@energiebespaarders/symbols';
import { Gray, Heading, Small } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import dayjs from 'dayjs';
import React from 'react';
import theme from '~/styles/theme';
import { getHighlightedBlogPosts_allBlogPosts } from '~/types/generated/cms/getHighlightedBlogPosts';
import { dateStringFormatters } from '~/utils/constants';
import { DatoImageWrapper, StyledDatoImage } from '../FAQ/Topic';
import Slider from '../Slider';

interface BlogSliderProps {
  posts: readonly getHighlightedBlogPosts_allBlogPosts[];
}

const BlogSlider: React.FC<BlogSliderProps> = ({ posts }) => {
  const isMobile = useIsMobile();

  return (
    <Flex style={{ background: theme.colors.grayLighter }}>
      <Container py={5}>
        <Flex flexWrap="wrap">
          <Box width={1} px={2} textAlign="center">
            <Heading heading="h2">Uitgelichte artikelen van onze blog</Heading>
          </Box>

          <Box width={1}>
            <Slider>
              {posts.map((post, index) => (
                <Card p={0} key={`newsSlide-${index}`}>
                  <Flex p={4} flexWrap="wrap" flexDirection={isMobile ? 'column-reverse' : 'unset'}>
                    <Box width={[1, 1 / 2, 1 / 2, 2 / 3]} pr={4} style={{ position: 'relative' }}>
                      <Gray>
                        <Small>{dayjs(post._publishedAt).format(dateStringFormatters.short)}</Small>
                      </Gray>

                      <Heading heading="h4" fontWeight={500}>
                        {post.title}
                      </Heading>

                      <Box mb={2}>{post.description}</Box>

                      <Box>
                        <Button mb={0} href={`/blog/${post.slug}`} label="Lees dit artikel" />
                      </Box>
                    </Box>

                    <Box width={[1, 1 / 2, 1 / 2, 1 / 3]} textAlign="center" mt={[2, 2, 0]}>
                      <DatoImageWrapper $height="235px" $rounded>
                        <StyledDatoImage data={post.image!.responsiveImage!} />
                      </DatoImageWrapper>
                    </Box>
                  </Flex>
                </Card>
              ))}
            </Slider>
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

export default BlogSlider;
