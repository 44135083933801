import { useQuery } from '@apollo/client';
import { Box, Container, Flex } from '@energiebespaarders/symbols';
import { Center } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import { Element as Anchor } from 'react-scroll';
import styled from 'styled-components';
import { padding, themify } from '../../styles/mixins';
import { getKlantenVertellenScore } from '../../types/generated/getKlantenVertellenScore';
import delimit from '../../utils/delimit';
import { GET_KLANTENVERTELLEN_REVIEWS } from '../KlantenVertellenWidget';

const StyledSection = styled.section<{ $bgColor: string }>`
  ${padding(10, 'y')};
  background: ${x => x.$bgColor};
`;

const Benefits: React.FC<{ bgColor?: string }> = ({ bgColor = 'floral' }) => {
  const { data: reviewData } = useQuery<getKlantenVertellenScore>(GET_KLANTENVERTELLEN_REVIEWS, {
    fetchPolicy: 'cache-first',
  });
  const reviewScore = reviewData?.getKlantenVertellenScore?.last12MonthAverageRating ?? 9;
  return (
    <Anchor name="benefits" id="benefits">
      <StyledSection $bgColor={themify(bgColor)}>
        <Container>
          <Center block>
            <h2>De voordelen op een rijtje</h2>
          </Center>
          <Flex flexWrap={['wrap', 'wrap', 'nowrap']} mx={[0, 0, -2]} px={[0, 0, 2]}>
            <Box width={1} px={2}>
              <h3 className="h4">Snel inzicht in jouw mogelijkheden</h3>
              <ul className="pros">
                <li>
                  Direct inzicht in de mogelijke duurzame maatregelen, terugverdientijden en kosten.
                </li>
                <li>Regel het zelf online wanneer het jou uitkomt.</li>
                <li>Regel meerdere installaties simpel via Energiebespaarders.</li>
              </ul>
            </Box>
            <Box width={1} px={2}>
              <h3 className="h4">Vind de beste oplossingen</h3>
              <ul className="pros">
                <li>Snel inzicht in jouw mogelijkheden met onze slimme software</li>
                <li>Onafhankelijk: we niet gebonden aan één merk of installateur</li>
                <li>Meer dan 10 jaar ervaring als verduurzamingsexpert</li>
              </ul>
            </Box>
            <Box width={1} px={2}>
              <h3 className="h4">Zorgeloos van A tot Z</h3>
              <ul className="pros">
                <li>Betrouwbare vakmensen en installatiebedrijven</li>
                <li>Extra garantie op de installatie</li>
                <li>Onze klanten beoordelen onze diensten met een {delimit(reviewScore, 1)}</li>
              </ul>
            </Box>
          </Flex>
        </Container>
      </StyledSection>
    </Anchor>
  );
};

export default Benefits;
