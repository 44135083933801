import flohilProfile from './flohil/flohil.jpg';
import nouwensProfile from './nouwens/nouwens.jpg';

import schutBg from './schut/bg_schut.jpg';
import schutProfile from './schut/schut.jpg';
import schut1 from './schut/schut_1.jpg';
import schut2 from './schut/schut_2.jpg';

import soetekouwBg from './soetekouw/bg_soetekouw.jpg';
import soetekouwProfile from './soetekouw/soetekouw.jpg';
import soetekouw1 from './soetekouw/soetekouw_1.jpg';

import vandenbroekBg from './vandenbroek/bg_vandenbroek.jpg';
import vandenbroekProfile from './vandenbroek/vandenbroek.jpg';
import vandenbroek1 from './vandenbroek/vandenbroek_1.jpg';

import vaneijkBg from './vaneijk/bg_vaneijk.jpg';
import vaneijkProfile from './vaneijk/vaneijk.jpg';
import vaneijk1 from './vaneijk/vaneijk_1.jpg';

import vanderschansBg from './vanderschans/bg_vanderschans.jpg';
import vanderschansProfile from './vanderschans/vanderschans2.jpg';
import vanderschans1 from './vanderschans/vanderschans.jpg';

import vanveldhovenBg from './vanveldhoven/bg_vanveldhoven.jpg';
import vanveldhovenProfile from './vanveldhoven/vanveldhoven.jpg';
import vanveldhoven1 from './vanveldhoven/vanveldhoven1.jpg';
import vanveldhoven2 from './vanveldhoven/vanveldhoven2.jpg';

import lansBg from './lans/lans.jpg';
import lansProfile from './lans/lansProfile.jpg';
import lans1 from './lans/lans_1.jpg';

import vandenboogaardBg from './vandenboogaard/bg_vandenboogaard.jpg';
import vandenboogaardProfile from './vandenboogaard/vandenboogaard.jpg';
import vandenboogaard1 from './vandenboogaard/vandenboogaard_1.jpg';

import wernernicolauBg from './wernernicolau/bg_wernernicolau.jpg';
import wernernicolauProfile from './wernernicolau/wernernicolau.jpg';
import wernernicolau1 from './wernernicolau/wernernicolau_1.jpg';
import wernernicolau2 from './wernernicolau/wernernicolau_2.jpg';

import suzannejacoBg from './suzannejaco/bg_suzannejaco.jpg';
import suzannejacoProfile from './suzannejaco/suzannejaco.jpg';
import suzannejaco1 from './suzannejaco/suzannejaco_1.jpg';

export default {
  flohilProfile,
  nouwensProfile,
  schutBg,
  schutProfile,
  schut1,
  schut2,
  soetekouwBg,
  soetekouwProfile,
  soetekouw1,
  vandenbroekBg,
  vandenbroekProfile,
  vandenbroek1,
  vaneijkBg,
  vaneijkProfile,
  vaneijk1,
  vanderschansBg,
  vanderschansProfile,
  vanderschans1,
  vanveldhovenBg,
  vanveldhovenProfile,
  vanveldhoven1,
  vanveldhoven2,
  lansBg,
  lansProfile,
  lans1,
  vandenboogaardBg,
  vandenboogaardProfile,
  vandenboogaard1,
  wernernicolauBg,
  wernernicolauProfile,
  wernernicolau1,
  wernernicolau2,
  suzannejacoBg,
  suzannejacoProfile,
  suzannejaco1,
};
