import { Box, Button, Card, Container, Flex, Icon, Image } from '@energiebespaarders/symbols';
import { Center, Color, Medium, Smaller } from '@energiebespaarders/symbols/helpers';
import { CaretLeft, CaretRight } from '@energiebespaarders/symbols/icons/solid';
import Link from 'next/link';
import React, { useState } from 'react';
import styled from 'styled-components';
import { padding, themify } from '~/styles/mixins';
import newTheme from '~/styles/theme';
import { TestimonialSlide, testimonials as defaultTestimonials } from './testimonials';

const StyledSection = styled.section<{ $bgColor: string }>`
  ${padding(10, 'y')};
  background: ${x => x.$bgColor};
`;

const StyledCard = styled(Card)`
  font-family: ${newTheme.type.fontFamilySerif};
  font-weight: 500;
  font-style: italic;
  text-align: center;
  min-height: 108px;
  display: flex;
  align-items: center;
`;

const TestimonialSlider: React.FC<{ testimonials?: TestimonialSlide[]; bgColor?: string }> = ({
  testimonials = defaultTestimonials,
  bgColor = 'transparent',
}) => {
  const [page, setPage] = useState(1);
  const amountPerPage = 3;
  return (
    <StyledSection $bgColor={themify(bgColor)}>
      <Container>
        <Center block>
          <h2>Dit zeggen onze klanten</h2>
        </Center>

        <Flex flexWrap="wrap" mx={[0, 0, -2]} px={[0, 0, 2]}>
          {testimonials.map((testimonial, index) =>
            (page === 1 && index >= amountPerPage) || (page === 2 && index < amountPerPage) ? (
              <React.Fragment key={`${testimonial.name}`}></React.Fragment>
            ) : (
              <Box key={`${testimonial.name}`} width={[1, 1, 1 / 3]} px={2}>
                <StyledCard fontSize={6} shadow="sm" p={2} mb={2}>
                  “{testimonial.quote}”
                </StyledCard>
                <Flex mb={2}>
                  <Box width={0.25} textAlign="right">
                    <Image
                      src={testimonial.photo}
                      alt={testimonial.name}
                      width="60px"
                      radius="100px"
                      // animation="floatIn"
                    />
                  </Box>
                  <Box width={1} px={2} style={{ lineHeight: 1 }}>
                    <Smaller>
                      <Color c="grayDark">
                        <div>
                          <Medium>{testimonial.name}</Medium>
                        </div>
                        {testimonial.subtitle}
                      </Color>
                    </Smaller>
                  </Box>
                </Flex>
              </Box>
            ),
          )}

          <Box width={1} textAlign="center">
            <Button
              minimal
              style={{ borderRadius: 100 }}
              mr={1}
              onClick={() => setPage(page === 2 ? 1 : 2)}
              aria-label="Vorige pagina"
            >
              <Icon icon={CaretLeft} fill="green" />
            </Button>
            <Button
              minimal
              style={{ borderRadius: 100 }}
              ml={1}
              onClick={() => setPage(page === 2 ? 1 : 2)}
              aria-label="Volgende pagina"
            >
              <Icon icon={CaretRight} fill="green" />
            </Button>
          </Box>
          <Box width={1} textAlign="center" mt={2}>
            <Smaller>
              <p>
                <Link href="/klanten">
                  <a title="Bekijk hier meer verhalen van onze klanten" className="link-lightgray">
                    Bekijk meer verhalen van onze klanten →
                  </a>
                </Link>
              </p>
            </Smaller>
          </Box>
        </Flex>
      </Container>
    </StyledSection>
  );
};

export default TestimonialSlider;
