import React from 'react';
import { Box, Button, Container, Flex } from '@energiebespaarders/symbols';
import { Large } from '@energiebespaarders/symbols/helpers';
import RebeccaJack from '/public/img/homePage/rejecka.jpg';
import styled from 'styled-components';
import { rgba } from 'polished';

const StyledBackground = styled(Flex)`
  background-image: ${x =>
    `linear-gradient(to right, ${rgba(x.theme.colors.purpleDark, 0.25)} 25%, ${rgba(
      x.theme.colors.purpleDark,
      1,
    )} 60%), url(${RebeccaJack})`};
  background-size: cover, auto;
  background-repeat: no-repeat;
  background-position:
    center,
    left -100px;
  color: ${x => x.theme.colors.floral};
`;

const WeAreHiring: React.FC = () => {
  return (
    <StyledBackground py={10}>
      <Container px={2}>
        <Flex flexWrap="wrap" alignItems="center">
          <Box width={1} textAlign="right">
            <h2 style={{ margin: 0 }}>Ben jij onze nieuwe collega?</h2>
            <Large>
              <p>Wij zijn op zoek naar talent wat met ons het verschil maakt!</p>
            </Large>

            <Button
              href="https://energiebespaarders.recruitee.com/"
              bgColor="orange"
              fontSize={5}
              px={4}
              mt={2}
              mr={0}
            >
              Bekijk onze vacatures →
            </Button>
          </Box>
        </Flex>
      </Container>
    </StyledBackground>
  );
};

export default WeAreHiring;
