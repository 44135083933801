import customerPhotos from 'public/img/customers';

export interface TestimonialSlide {
  name: string;
  quote: string;
  subtitle: string;
  photo: string;
}

export const testimonials: TestimonialSlide[] = [
  {
    name: 'Familie Soetekouw',
    quote:
      'Je hoeft niet meer in zee met allemaal verschillende bedrijven. Je legt alles gewoon bij Energiebespaarders neer en beslist uiteindelijk zelf.',
    subtitle: 'Bespaart energie met spouwmuurisolatie, vloerisolatie, zonnepanelen en een HR-ketel',
    photo: customerPhotos.soetekouwProfile,
  },
  {
    name: 'Thomas van Veldhoven',
    quote:
      'Ik vind het erg leuk dat ik op die manier mijn ouders heb geïnspireerd om ook duurzamer te gaan leven!',
    subtitle: 'Bespaart energie met vloerisolatie en zonnepanelen',
    photo: customerPhotos.vanveldhovenProfile,
  },
  {
    name: 'André van Eijk',
    quote:
      'Eén centraal aanspreekpunt, heldere uitleg over de aangeboden oplossingen en veel technische kennis over wat ze adviseren.',
    subtitle: 'Bespaart energie met spouwmuurisolatie',
    photo: customerPhotos.vaneijkProfile,
  },
  {
    name: 'Familie van den Broek',
    quote:
      'Het is een groep leuke jonge mensen en ze hebben een enorme drive. Ze zijn deskundig en geven adviezen die hout snijden.',
    subtitle: 'Wekt energie op met zonnepanelen',
    photo: customerPhotos.vandenbroekProfile,
  },
  {
    name: 'Familie Schut',
    quote:
      'Het wordt voor mij als leek simpel gemaakt en teruggebracht tot de essentie. Dat vind ik erg prettig aan hun manier van werken.',
    subtitle: 'Wekt energie op met zonnepanelen',
    photo: customerPhotos.schutProfile,
  },
  {
    name: 'Familie Flohil',
    quote: 'Ik hoefde niks te doen. Alleen een kopje koffie zetten, ze gingen meteen aan de slag.',
    subtitle: 'Bespaart energie met spouwmuurisolatie',
    photo: customerPhotos.flohilProfile,
  },
  // {
  //   name: 'Familie Nouwens',
  //   quote: 'Het verbaasde me eigenlijk hoe snel het allemaal is gegaan.',
  //   subtitle: 'Bespaart energie met dakisolatie',
  //   photo: nouwens,
  // },
];
